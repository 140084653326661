.hero-container {
  display: flex;
  background: #050505;
  color: white;
  justify-content: center;
  align-items: center;
  padding: 5rem 10rem 0rem 10rem;
  font-family: "Montserrat", sans-serif;
}

.hero-text h1 {
  font-weight: 700;
  font-size: 100px;
  line-height: 122px;
  color: #f2771a;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f2771a;
  margin: 0;
}

.hero-text h2 {
  font-weight: 700;
  font-size: 70px;
  line-height: 85px;
  margin: 0;
  background: linear-gradient(
    179.62deg,
    rgba(255, 255, 255, 0.4) 0.33%,
    rgba(82, 82, 82, 0) 110.78%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.hero-text p {
  margin-top: 1rem;
  font-size: 20px;
  line-height: 24px;
  width: 70%;
}

.hero-container img{
    width: 35%;
}


@media only screen and (max-width: 480px) {
  .hero-container{
    flex-direction: column;
    padding: 2rem;
  }

  .hero-text h1{
    font-size: 50px;
    line-height: 60px;
  }

  .hero-text h2{
    font-size: 35px;
    line-height: 50px;
  }

  .hero-text p{
    font-size: 15px;
    width: 100%;
  }

  .hero-container img{
    width: 100%;
  }
}