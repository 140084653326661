.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  font-family: "Montserrat", sans-serif;
  color: white;
}

.modal-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
  background: white;
  width: max-content;
  height: max-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  padding: 2rem 4rem;
  background: #1c1c1c;
}


.modal-close-btn {
  position: absolute;
  right: 20px;
  align-self: center;
  background: #f2771a;
  border: none;
  color: white;
  font-size: 20px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal-header h3 {
  color: #f2771a;
  font-size: 25px;
  margin: 0;
}

.modal-header h4 {
  color: #454545;
  font-size: 25px;
  margin: 0 0 1rem 0;
}

.modal-form-component {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.form-submit-btn {
  width: max-content;
  background: #f2771a;
  border: none;
  color: black !important;
  font-size: 20px;
}

.form-submit-btn:hover{
  background: #d46916;
  cursor: pointer;
}

.modal-form input {
  height: 40px;
  background: transparent;
  border-bottom: 1px solid #f2771a;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  color: #8f8e8e;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}

.modal-form input:placeholder-shown{
  color: #8f8e8e;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}

.modal-form input:focus {
  outline: solid 2px #f2771a;
  padding-left: 0.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size:15px;
  color: #8f8e8e;
}

.modal-form input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #f2771a;
  -webkit-text-fill-color: #8f8e8e;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-text-size-adjust: 18px;
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-checkbox{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-checkbox input{
  display: flex;
  flex-direction: row;
}

input[type="checkbox"]{
  width: 20px;
  height: 20px;
}

.options{
  padding-left: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 15px;
}

#newsletter{
  padding-left: 0rem !important;
}

.detail{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.square {
  width: 56px;
  height: 56px;
  background: #f2771a;
}

/* For Home Screen */

.contact-form{
  position: relative;
  top: 0%;
  left: 0%;
  transform: translate(0%, 0%);
}

.contact-form-container{
  width: 100%;
  background: #050505;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Montserrat", sans-serif;
  padding-top: 10rem;
}

#newsletter {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  font-size: 18px;
}

#newsletter input {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 0.15em solid #f2771a;
  outline: none;
  cursor: pointer;
}

#newsletter input:checked {
  background-color: #f2771a;
  position: relative;
}

#newsletter input:checked::before {
  content: "\2714";
  font-size: 1em;
  color: #fff;
  position: absolute;
  left: 1px;
}


.options input {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 0.15em solid #f2771a;
  outline: none;
  cursor: pointer;
}

.options input:checked {
  background-color: #f2771a;
  position: relative;
}

.options input:checked::before {
  content: "\2714";
  font-size: 1em;
  color: #fff;
  position: absolute;
  left: 1px;
}

.options input:focus{
  border: none;
}

.alert{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  font-family: "Montserrat", sans-serif;
  color: white;
  display: none;
  transition: 0.2s;
}

#alert-content{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
  background: white;
  width: max-content;
  height: max-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  padding: 1rem 2rem;
  background: #1c1c1c;
  color: #f2771a;
  font-size: 22px;
}

@media only screen and (max-width: 480px) {

  .contact-form-container{
    padding-top: 5rem;
  }

  .modal-main{
    max-width: 80vw;
    padding:1rem;
  }

  .modal-form input{
    width: 100%;
    align-self: center;
  }

  .modal-form-component{
    flex-direction: column;
  }

  .contact-form{
    padding-left: 2rem;
  }

  .options input{
    width: 15px;
    height: 15px;
    margin: 0;
  }

  .options{
    width: 100%;
    padding: 0;
    align-items: flex-start;
  }

  #newsletter{
    gap: 1rem;
  }

  #newsletter p{
    font-size: 12px;
  }
  #newsletter input{
    width: 20px;
    height: 15px;
  }

}
