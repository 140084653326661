.newlaunch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #050505;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.newlaunch-container h1{
    font-size: 40px;
}

.launch-cards {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 5rem 15rem;
  align-items: center;
  justify-content: center;
}

.launch-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.launch-card-desc {
  width: 60%;
}

.launch-card-desc h2 {
  color: #f2771a;
  font-weight: 600;
  font-size: 30px;
}

.launch-cards img {
  width: 351px;
  height: auto;
  background: rgba(217, 217, 217, 0.11);
}

.launch-card-desc p{
    font-weight: 400;
font-size: 18px;
}

@media only screen and (max-width:480px) {

  .launch-card{
    flex-direction: column;
    gap: 1rem;
  }

  .launch-cards{
    padding: 2rem 0;
  }

  .launch-card-desc{
    width: 100%;
  }

  .launch-card img{
    width: 70%;
  }
  
}
