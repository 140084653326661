.products-container {
  background: #050505;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Montserrat", sans-serif;
  padding-top: 10rem;
}

.products-container h1 {
  font-weight: 700;
  font-size: 40px;
}

.products {
  margin-top: 4rem;
  display: flex;
  width: 60vw;
  justify-content: space-between;
}

.product {
  height: 12rem;
  width: 12rem;
  padding: 20px;
  background: #1c1c1c;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1.5px -1px 2px 0px #f2771a;
}

.product p {
  margin-top: 1rem;
  color: #f2771a;
  font-weight: 600;
  font-size: 21px;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width:480px) {

  .products-container{
    padding-top: 4rem;
  }

  .products-container h1{
    font-size: 30px;
    text-align: center;
  }

  .products{
    flex-direction: column;
    gap: 2rem;
  }

  .product{
    height: auto;
    width: auto;
  }

  .product p{
    font-size: 18px;
  }
}
