.usecases{
    margin-top: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 5rem 30rem;
}

.usecase{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15rem;
}

.usecase h3{
    color: #F2771A;
    align-self: flex-start;
}

.usecase img{
    width: 70%;
}

@media only screen and (max-width:480px){
    .usecases{
        grid-template-columns: 1fr;
    }
}
