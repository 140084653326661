nav {
  background: #050505;
}

header{
  position: sticky;
  top: 0;
  background: #050505;
  padding: 1rem 4rem;
  z-index: 10000;
}

.navbar {
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav-right button {
  position: relative;
  width: 123px;
  height: 50px;
  background: #f2771a;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: white;
  cursor: pointer;
  z-index: 1;
}

.navbar button:hover{
    background: #d46916;
}

.nav-right{
  color: white;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  font-size: 18px;
}

.nav-right li{
  list-style: none;
  padding: 0.5rem 1rem;
}

.nav-right li:hover{
  cursor: pointer;
  background: #f2771a;
  padding: 0.5rem 1rem;
}

.nav-btn:hover{
  background: transparent !important;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #f2771a;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: relative;
  display: none;
  z-index: 1000;
}

.hamburger:hover {
  background-color: #d46916;
}


@media only screen and (max-width: 480px) {
    header{
      padding: 1rem 2rem;
    }

    .navbar{
        padding-top: 2rem;
    }

    .navbar img{
      width: 40%;
    }

    .hamburger {
      display: block;
      margin-left: 10rem;
    }

    .nav-right{
      gap: 1rem;
      z-index: 1000;
    }

    .nav-right ul { 
      position: absolute;
      left: 0;
      top: 80px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: max-content;
      background-color: #f2771a;
      display: none;
      padding: 0;
    }

    .nav-right.expanded ul {
      display: block;
      transition: 2s;
    }
  
    .nav-right li {
      text-align: center;
      color: white;
      font-size: 1.2rem;
      font-family: "Montserrat", sans-serif;
    }

    .nav-btn button{
      border: 2px solid #d46916;
    }
}