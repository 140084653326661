.footer-component {
  position: relative;
  bottom: 0;
  background: #313131;
  z-index: 100;
}

footer {
  position: relative;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:2rem 0 2rem 0; 
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-desc {
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.mail-us {
  color: #ffffff;
}

.mail-us h3 {
  font-weight: 600;
}

.mail-us h4 {
  font-weight: 100;
  color: #b8b8b8;
  margin-top: -15px;
}

.mail-us p {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
}

.custom-input {
  display: flex;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 5px;
}

.custom-input input {
  width: 280px;
  height: 40px;
  background: #ededfc;
  border: none;
  border-radius: 7px;
}

.custom-input input:placeholder-shown {
  padding-left: 0.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
}

.custom-input input:focus {
  outline: solid 2px #f2771a;
  padding-left: 0.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #313131;
}

.custom-input button {
  display: flex;
  align-items: center;
  position: relative;
  right: 2.5rem;
  background: #f2771a;
  border: none;
  border-radius: 5px;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
}

.custom-input button:hover {
  background: #d46916;
}

@media only screen and (max-width: 480px) {

  footer {
    gap: 1rem;
    justify-content: center;
  }

  .mail-us h3{
    font-size: 20px;
  }

  .mail-us {
    align-self: flex-start;
    width: 100%;
  }

  .contact {
    align-self: flex-start;
  }

  .contact-desc p {
    justify-content: flex-start;
    font-size: 18px;
  }
}
